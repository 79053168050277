import CustomSourceFlowText from "@/components/Shared/CustomSourceflowText";
import ExpandingButton from "@/components/Buttons/ExpandingButton";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import ArrowIcon from "@/assets/icons/arrow-white.svg";
import serviceCover from "@/assets/homepage/service-cover.png";
import Link from "next/link";
import useLocale from "@/hooks/useLocale";
import localizePath from "@/functions/localizePath";

export default function OurService({ background, path = "service" }) {
  const locale = useLocale();

  return (
    <div className="our-services container z-[100] relative">
      <span className="text-blue text-lg font-semibold pb-[22px] block">
        <CustomSourceFlowText
          title="OUR SERVICES"
          path={`dynamic1-header.${path}`}
          locale={locale}
        />
      </span>
      <div className="flex justify-between flex-col gap-4 lg:gap-[55px] lg:flex-row">
        <Link
          className="lead-card relative w-full lg:min-h-[900px] max-h-[970px] lg:max-w-[70%]"
          href={localizePath("/retained-search", locale)}
        >
          <div className="h-full">
            <SourceFlowImage
              src={serviceCover ?? ""}
              size="827x759"
              alt="team"
              className="w-full h-full"
            />
            <div className="absolute w-full top-0 p-10 md:pl-[68px] md:pt-[58px] md:pr-[37px] md:pb-[34px] h-full flex flex-col justify-between">
              <div>
                <p className="text-3xl sm:text-[40px] xl:text-[50px]/[60px] text-blue">
                  <CustomSourceFlowText
                    title="Retained Search."
                    path={`dynamic1.${path}`}
                    locale={locale}
                  />
                </p>
                <p className="text-xl md:text-2xl lg:text-3xl">
                  <CustomSourceFlowText
                    title="Our most comprehensive solution yet."
                    path={`dynamic1-subtitle.${path}`}
                    locale={locale}
                  />
                </p>
              </div>

              <ExpandingButton
                url={localizePath("/retained-search", locale)}
                color="white"
                bgColor="transparent"
                hoverBGColor="white"
                hoverColor="#150235"
                notLink={true}
              >
                <CustomSourceFlowText
                className="text-[16px] lg:text-xl xl:text-xl"
                  title={locale === "de" ? "Lerne mehr" : "Learn more"}
                  path={`dynamic1-learnmore.${path}`}
                  locale={locale}
                />
              </ExpandingButton>
            </div>
          </div>
        </Link>

        <div className="flex flex-col justify-between lg:max-w-[40%] w-full gap-4 lg:gap-[55px]">
          <Link
            href={localizePath("/executive-search", locale)}
            className={`${
              background ? background : "bg-purple-15"
            } sub-card group/item relative placeholder:text-white placeholder:text-xl placeholder:md:text-2xl placeholder:lg:text-3xl text-xl md:text-2xl lg:text-3xl text-white resize-none border-[#ffffff] border-[2px] hover:bg-white hover:text-[#150235] focus:ring-0 md:h-1/2 h-full py-10 md:py-10 px-10 md:px-11`}
          >
            <div>
              <CustomSourceFlowText
                title={
                  <>
                    <h5> Executive Search</h5>
                    <p>Our most comprehensive solution yet.</p>
                  </>
                }
                path={`dynamic1-item-1.new.${path}`}
                locale={locale}
              />
            </div>
            <div className="absolute cursor-pointer hidden bottom-[-2px] right-[-2px] bg-purple w-12 h-12 z-10 group-hover/item:flex justify-center items-center">
              <ArrowIcon className="fill-white" />
            </div>
          </Link>
          <Link
            href={localizePath("/contingent-search", locale)}
            className={`${
              background ? background : "bg-purple-15"
            } sub-card group/item relative placeholder:text-white placeholder:text-xl placeholder:md:text-2xl placeholder:lg:text-3xl text-xl md:text-2xl lg:text-3xl text-white resize-none border-[#ffffff] border-[2px] hover:bg-white hover:text-[#150235] focus:ring-0 md:h-1/2 h-full py-10 md:py-10 px-10 md:px-11`}
          >
            <div className="opacity-100">
              <div>
                <CustomSourceFlowText
                  title={
                    <>
                      <h5>Contingent Search</h5>
                      <p>
                        Identify high-level executives with Lawrence Harvey.
                      </p>
                    </>
                  }
                  path={`dynamic1-item-2.new.${path}`}
                  locale={locale}
                />
              </div>
              <div className="absolute cursor-pointer hidden bottom-[-2px] right-[-2px] bg-purple w-12 h-12 z-10 group-hover/item:flex justify-center items-center">
                <ArrowIcon className="fill-white" />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
